import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
function Menu() {
    const location = useLocation();
    const shopInfo = useSelector((state) => state?.Auth?.shopInfo);
    const [isOnBoarding, setIsOnBoarding] = useState(false);
    useEffect(() => {
        setIsOnBoarding(shopInfo?.on_boarding);
    }, [shopInfo?.on_boarding]);
    return (
        <>
            {(!isOnBoarding || location.pathname !== "/onboarding") && (
                <ui-nav-menu>
                    <Link to="/" rel="home">
                        Home
                    </Link>
                    <Link to="/review-management">Manage Reviews</Link>
                    <Link to="/email-review">Email Requests</Link>
                    <Link to="/widget">On-site Displays</Link>
                    <Link to="/group">Group Product</Link>
                    <Link to="/marketing-social">Marketing & Social</Link>
                    <Link to="/setting?tab=1">Settings & Pricing</Link>
                </ui-nav-menu>
            )}
        </>
    );
}

export default Menu;
